import React from 'react';
import './App.scss';
import Board from './Board'

function App() {
  return (
    <Board></Board>
  );
}

export default App;
